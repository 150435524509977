import getPageData from '../server/getPageData.server'
import last from 'lodash/last'
import find from 'lodash/find'
import { resolveInternalLinkUrl } from '../lib/resolveLink'
import PageComponent from '../components/PageComponent'
import getCustomerGroups from '../server/getCustomerGroups'
import { isProductVisible } from '../components/Slices/ProductListing/productHooks'

const HOME_SLUG = 'home'

export default function Page (props) {
  return <PageComponent {...props} />
}

export async function getStaticProps ({
  preview = false,
  previewData,
  params,
  wholesale
}) {
  const { slug = [HOME_SLUG] } = params || {}

  if (last(slug) === '__webpack_hmr') return { notFound: true }

  let pageData = await getPageData(last(slug), {
    active: preview,
    token: previewData?.token
  })

  const path = `/${slug.join('/')}`
  const pageRedirect = pageData?.redirects?.find(r => r.source === path)
  if (pageRedirect) {
    return {
      redirect: {
        destination: pageRedirect.destination,
        permanent: pageRedirect.permanent
      }
    }
  }

  if (!pageData || !pageData.page) {
    return {
      notFound: true
    }
  }

  const customerGroups = await getCustomerGroups()
  pageData = {
    ...pageData,
    customerGroups
  }

  if (pageData.page._type === 'product') {
    const guestCustomerGroup = find(customerGroups, x => x.is_group_for_guests)
    // If the product is only visible to wholesale customer, then we need to send them to the server generated page so that
    // we can check that they are authenticated before serving the page
    if (!wholesale) {
      if (!isProductVisible(pageData.page, guestCustomerGroup)) {
        // const redirect = `/wholesale/${resolveInternalLinkUrl(pageData.page)}`
        const redirect = resolveInternalLinkUrl({
          ...pageData.page,
          wholesale: true
        })
        return {
          redirect: {
            destination: redirect,
            permanent: true
          }
        }
      }
    }
  }

  return {
    props: {
      data: pageData,
      preview
    },
    revalidate: 60
  }
}

export async function getStaticPaths () {
  return { paths: [], fallback: 'blocking' }
}
