import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import { ProductListingItem } from './ProductListing/ProductListingItem'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import { useProductCustomerGroupFiltering } from './ProductListing/productHooks'

export default function relatedProducts ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.tile}`), {
      start: '30% bottom',
      onEnter: item => gsap.to(item, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1
      })
    })
  }, []))

  const { title, automatic, products } = data
  const renderedProducts = automatic ? data.relatedProducts : products
  const filteredProducts = useProductCustomerGroupFiltering(renderedProducts || [])
  const slicedProducts = automatic ? filteredProducts.slice(0, 3) : filteredProducts

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <h3 className={classes.title}>{title}</h3>
      {slicedProducts && slicedProducts.map(product => (
        <ProductListingItem product={product} link={product} key={product._id} className={classes.tile}/>
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    gridColumn: '1 / -1',
    textTransform: 'uppercase',
    fontSize: 40,
    lineHeight: '0.9em',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  },
  tile: {
    gridColumn: 'span 4',
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 2'
    }
  }
})
